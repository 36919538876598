import React, { useContext } from "react"
import { Formik, Form } from "formik"

import MedicineInfo from "./MedicineInfo"
import TotalPriceInfo from "./TotalPriceInfo"
import MedicineQuantityInput from "./MedicineQuantityInput"
import MedicineDosageInput from "./MedicineDosageInput"

import { AppContext } from "../../../context/AppContext"
import { changeQuantity } from "../services/cart"
import { addMedicineModalValidationSchema } from "../utils/formData"

const AddMedicineModal = ({ addToCart, medicine }) => {
  const { dispatch } = useContext(AppContext)

  return (
    <Formik
      initialValues={{ qty: 10, dailyIntake: "", indication: "" }}
      onSubmit={(values) => {
        dispatch({ type: "HIDE_MODAL" })
        const others = values.otherSpecifications
          ? values.otherSpecifications
          : "NA"
        if (addToCart)
          addToCart(
            medicine,
            values.qty,
            values.indication,
            values.dailyIntake,
            others
          )
      }}
      validationSchema={addMedicineModalValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mx-4 mx-1-mobile">
            <MedicineInfo medicine={medicine} />
            <MedicineQuantityInput
              changeQuantity={changeQuantity}
              setFieldValue={setFieldValue}
              qty={values.qty}
            />

            <hr className="has-background-light my-0" />
            <TotalPriceInfo medicine={medicine} qty={values.qty} />
            <MedicineDosageInput setFieldValue={setFieldValue} />
            <button
              type="submit"
              className="button is-fullwidth is-primary mt-2"
              disabled={
                values.qty < 1 || !isNaN(parseFloat(values.dailyIntake))
                  ? parseFloat(values.dailyIntake) === 0
                  : true || !values.indication
              }
            >
              Add to cart
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddMedicineModal
